import React, { Fragment, useCallback, useState } from 'react';
import { Divider, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Paper } from '@mui/material';
import { ReactComponent as LogoutIcon } from '../../assets/icons/logout.svg';
import { ReactComponent as Logo } from '../../assets/logos/enru-small.svg';
import { useAuth } from '../../hooks/useAuth';
import { CustomDialog } from '../UI/CustomDialog';
import { msalInstance } from '../../authConfig';
import { useLocation, useNavigate } from 'react-router-dom';
import { getEnv } from '../../utils';
import { getMenuItems } from '../../config/routeConfig';
import PropTypes from 'prop-types';

const SideMenu = ({ handleClick }) => {
  const { getUser } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [logoutDialogVisibility, setLogoutDialogVisibility] = useState(false);

  const handleLogoutClick = useCallback(() => {
    setLogoutDialogVisibility(true);
  }, []);

  const handleLogout = () => {
    msalInstance.logoutRedirect();
  };

  const handleLocation = item => {
    if (!item.path) return;
    navigate(item.path);
  };

  const checkActivePath = (activePath, queryParams, item) => {
    const itemURL = new URL(item.path, window.location.origin);
    const itemPath = itemURL.pathname;
    const itemQueryParams = new URLSearchParams(itemURL.search);

    // First, check if the paths match
    if (activePath !== itemPath) return false;

    // If the paths match, check the 'layers' query parameter if it exists
    if (queryParams.has('layers') && itemQueryParams.has('layers')) {
      return queryParams.get('layers') === itemQueryParams.get('layers');
    }

    if (itemQueryParams.has('layers') && !queryParams.has('layers')) return false;
    if (!itemQueryParams.has('layers') && queryParams.has('layers')) return false;

    // If no layers parameter is involved, but paths match, consider it active
    return true;
  };

  const getActiveMenuItems = () => {
    const activePath = location.pathname;
    const queryParams = new URLSearchParams(location.search);

    return getMenuItems(getEnv() === 'development').map(item => ({
      ...item,
      active: checkActivePath(activePath, queryParams, item),
    }));
  };

  const goHome = () => {};

  return (
    <>
      <div className="sidebar--menu">
        <div className="inner-container">
          <Paper className="side-menu">
            <div className="head">
              <IconButton className="b-home" onClick={goHome}>
                <Logo />
              </IconButton>
              <Logo />
            </div>
            <div className="inner-container">
              <List>
                {getActiveMenuItems().map((menuItem, index) => (
                  <Fragment key={menuItem.key || index}>
                    <ListItemButton
                      // className={menuItem.active ? 'active' : ''}
                      selected={menuItem.active}
                      onClick={() => handleLocation(menuItem)}
                      data-testid={menuItem.testId}
                    >
                      {menuItem.icon && <ListItemIcon>{menuItem.icon}</ListItemIcon>}
                      <ListItemText primary={menuItem.text} />
                    </ListItemButton>
                    {menuItem.subItems && (
                      <List component="div" disablePadding>
                        {menuItem.subItems.map((subItem, subIndex) => (
                          <ListItemButton
                            data-testid={subItem.testId}
                            selected={subItem.active}
                            key={subIndex}
                            onClick={() => handleLocation(subItem)}
                            sx={{ pl: 7 }}
                          >
                            {subItem.text}
                          </ListItemButton>
                        ))}
                      </List>
                    )}
                  </Fragment>
                ))}
                <Divider sx={{ height: `calc(100vh - ${getEnv() === 'development' ? '950' : '738'}px)` }} />
                <ListItemButton onClick={handleLogoutClick} data-testid="main-menu_logout_button">
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText primary="Logout" />
                </ListItemButton>
              </List>
            </div>
          </Paper>
        </div>
      </div>
      <CustomDialog
        id="logout"
        title="Logout"
        content="Are you sure you want to logout?"
        open={logoutDialogVisibility}
        handleConfirm={handleLogout}
        buttonConfirmTitle="Logout"
        handleCancel={() => setLogoutDialogVisibility(false)}
      />
    </>
  );
};

SideMenu.propTypes = {
  handleClick: PropTypes.func,
};

export default SideMenu;
